.App-logo {
  height: 80px;
}

.App-header {
  background-color: #232A2A;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-intro {
  font-size: large;
}

